/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
    Form, Segment, Header, Button, Loader, Dimmer, Dropdown, Modal, Table
} from 'semantic-ui-react';
/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Utility from '../../../Utility';
import Settings from '../../../Settings';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
import DropdownBranch from '../../Widget/DropDown/DropDownBranch'

function sortByProperty(property) {
    return function (a, b) {
        if (a[property] > b[property])
            return 1;
        else if (a[property] < b[property])
            return -1;

        return 0;
    }
}

class NewCustomerPrintPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                start_date: moment(),
                end_date: moment(),
            },
            items: [],
        }

        this.resetForm = this.resetForm.bind(this)
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.handlerInput = this.handlerInput.bind(this)
    }

    componentDidMount() {
        var url_string = window.location.toString();
        var url = new URL(url_string);
        var temp_start = url.searchParams.get("start_date");
        var temp_end = url.searchParams.get("end_date");
        this.setState({
            search: {
                start_date: temp_start ? moment(temp_start, 'DD/MM/YYYY') : moment(),
                end_date: temp_end ? moment(temp_end, 'DD/MM/YYYY') : moment(),
            }
        })
    }

    resetForm(e) {
        this.setState({
            search: {
                start_date: moment(),
                end_date: moment(),
            }
        })

    }

    handlerSubmit(e) {
        e.preventDefault();
        let search = Utility.cloneObjectJson(this.state.search)
        search.start_date = Utility.formatDate2(search.start_date)
        search.end_date = Utility.formatDate2(search.end_date)
        let fromData = Utility.jsonToQueryString(search)
        let url = Settings.baseReportUrl + "/report/new-customer/?" + fromData

        this.setState({
            open: true,
            loading: true,
            items: [],
        })
        Utility.get(url, (s, d) => {
            if (s == true) {
                this.setState({
                    loading: false,
                    items: d.data,
                })
            }
        })
    }

    handlerInput(e, v) {
        let search = this.state.search
        search[v.name] = v.value
        this.setState({
            search: search,
        })
    }


    render() {
        let title = 'รายงานลูกค้าใหม่';
        let filename = 'new-customer';

        const divStyle = {
            color: '#111',
            'font-size': '10px',
            width: '100%',
            'line-height': '20px',
            'font-family': 'monospace'
        };
        const textRight = {
            'text-align': 'right'
        }

        const textLeft = {
            'text-align': 'left'
        }
        const textCenter = {
            'text-align': 'center'
        }
        const textU = {
            'text-decoration': 'underline'
        }

        const productWidth = {
            'width': '250px',
            'word-break': 'break-word',
        }

        return (<div>
            <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
            }}>
                <Form >
                    <Form.Group>
                        <Form.Field>
                            <Header floated='left' as='h4'>{title}</Header>
                        </Form.Field>
                    </Form.Group>
                </Form>
                <div className="box-login">
                    <Segment textAlign='left' >
                        <Header size='small'>เงื่อนไขค้นหา</Header>
                        <Form size='small' onSubmit={this.handlerSubmit}>
                            <Form.Field >
                                <label>จากวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.start_date}
                                    selected={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        if (this.state.search.end_date < date) {
                                            this.handlerInput(null, {
                                                name: 'end_date',
                                                value: date
                                            });
                                        }
                                        this.handlerInput(null, {
                                            name: 'start_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>ถึงวันที่</label>
                                <DatePicker
                                    dateFormat="DD/MM/YYYY"
                                    value={this.state.search.end_date}
                                    selected={this.state.search.end_date}
                                    minDate={this.state.search.start_date}
                                    onChange={(date) => {
                                        date = date ? date : moment()
                                        this.handlerInput(null, {
                                            name: 'end_date',
                                            value: date
                                        });
                                    }}
                                />
                            </Form.Field>
                            <Button>พิมพ์</Button>
                            <Button type='button' onClick={this.resetForm}>รีเซ็ต</Button>
                        </Form>
                    </Segment>
                </div>
                <Modal open={this.state.open} onClose={this.close} size='fullscreen' /*dimmer='blurring'*/>
                    <Modal.Header>Preview</Modal.Header>
                    <Modal.Content className='scrolling'>
                        {this.state.loading && <Dimmer active={this.state.loading} inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>}
                        <div id='view-print'>
                            <div id='paperA4-portrait'>
                                <Table basic id='table-to-xls' style={divStyle}>
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='20'><center>{title}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row >
                                            <Table.HeaderCell colSpan='20'><center id='date'>วันที่ : {Utility.formatDate(this.state.search.start_date)} ถึงวันที่ {Utility.formatDate(this.state.search.end_date)}</center></Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.HeaderCell style={textCenter}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>รหัส</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>คำนำหน้า</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ชื่อ-นามสกุล</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เลขบัตร</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>เบอร์โทร</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เพศ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันเกิด</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>สัญชาติ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>เชื้อชาติ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ที่อยู่</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>ตำบล</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>อำเภอ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>จังหวัด</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>รหัสไปรษณีย์</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>อาชีพ</Table.HeaderCell>
                                            <Table.HeaderCell style={textLeft}>สถานที่ทำงาน</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ลงทะเบียนแอพ</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>ไม่ประสงค์ให้ข้อมูล</Table.HeaderCell>
                                            <Table.HeaderCell style={textCenter}>วันที่สร้าง</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.items.map((row, i) =>
                                            <Table.Row key={i}>
                                                <Table.Cell style={textCenter}>{Utility.numberFormat(i + 1)}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.code}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.prefix || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.name}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.citizen_id || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.mobile}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.gender}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.birth_date ? Utility.formatDate(row.birth_date) : '-'}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.nationality || '-'}</Table.Cell>
                                                <Table.Cell style={textCenter}>{row.ethnicity || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.address || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.district || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.city || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.province || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.postal_code || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.career__name || '-'}</Table.Cell>
                                                <Table.Cell style={textLeft}>{row.workplace__name || '-'}</Table.Cell>
                                                <Table.Cell style={textCenter}>{String(Boolean(row.member_customer))}</Table.Cell>
                                                <Table.Cell style={textCenter}>{String(row.is_not_inform)}</Table.Cell>
                                                <Table.Cell style={textCenter}>{Utility.formatDateTime(row.created_date)}</Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="ui primary button small"
                            table="table-to-xls"
                            filename={filename}
                            sheet={title}
                            buttonText="Download as XLS" >
                        </ReactHTMLTableToExcel>
                        <Button primary icon='print' size='small' onClick={() => {
                            var mywindow = window.open('', 'PRINT', 'height=600,width=1024');
                            mywindow.document.write('<html><head><title>' + title + '</title>');
                            mywindow.document.write('</head><body style="font-size: 8px;font-family: Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif">');
                            mywindow.document.write(document.getElementById('view-print').innerHTML);
                            mywindow.document.write('</body></html>');
                            mywindow.document.close(); // necessary for IE >= 10
                            mywindow.focus(); // necessary for IE >= 10*/
                            mywindow.print();
                            setTimeout(() => {
                                mywindow.close();
                            }, 2000)
                        }} labelPosition='right' content='Print' />
                        <Button id='btnClosePreview' size='small' type='button' onClick={() => { this.setState({ open: false }) }}>ปิด</Button>
                    </Modal.Actions>
                </Modal>
            </Segment>
        </div>)
    }
}

const mapStateToProps = state => {
    return ({
        auth: state.auth,
        branches: state.branches
    })
}

export default connect(
    mapStateToProps,
)(NewCustomerPrintPreview)
