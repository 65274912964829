/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
var config = require('./config')

// VERSION
const VERSION = '1.20.7';

const Settings = {
  mode: process.env.NODE_ENV,
  version: VERSION,
  invoice_info: {
    name: 'บริษัท ห้างทอง เอเอ เยาวราช จำกัด',
    address1: '123/20,123/21 ถนนฉลองกรุง',
    address2: 'แขวงลำปลาทิว เขตลาดกระบัง กรุงเทพมหานคร 10520',
    citizen_id: '0105558153546'
  },
  'app_name': '',
  'baseUrl': config.api.url + '/v2',
  'baseUrlV3': config.api.url + '/v3',
  'baseReportUrl': config.api.url_report + '/v2',
  'url_genpdf_qrcode': config.url_genpdf + '/qrcode',
  'url_genpdf_lease': config.url_genpdf + '/lease',
  'mediaUrl': config.api.url,
  'authUrl': config.api.url + '/o/token/',
  'revokeTokenUrl': config.api.url + '/o/revoke_token/',
  'client_id': config.client.id,
  'client_secret': config.client.secret,
  'baseMobileUrl': config.baseMobileUrl,
  'expenseUrl': config.link.expense,
  'table_width': 1200,
  'table_hiegh': 300,
  'input_size': 'tiny',
  'papter88': 332.598,
  'papterA4': 2480,
  'payment': {
    'CD': 'บัตร',
    'CS': 'เงินสด',
    // 'CH': 'เช็ค',
    'CC': 'บัตร+เงินสด',
    'TF': 'โอน'
  },
  'payment_option': [
    {
      key: 'CD',
      value: 'CD',
      text: 'บัตร'
    }, {
      key: 'CS',
      value: 'CS',
      text: 'เงินสด'
    },
    // {
    //   key: 'CH',
    //   value: 'CH',
    //   text: 'เช็ค'
    // }, 
    {
      key: 'CC',
      value: 'CC',
      text: 'เงินสด+บัตร'
    },
    {
      key: 'TF',
      value: 'TF',
      text: 'ต่อดอกออนไลน์'
    },
    {
      key: 'QR',
      value: 'QR',
      text: 'โอน'
    },
    {
      key: 'CSQ',
      value: 'CSQ',
      text: 'เงินสด+โอน'
    },
    {
      key: 'CDQ',
      value: 'CDQ',
      text: 'โอน+บัตร'
    },
    {
      key: 'CCQ',
      value: 'CCQ',
      text: 'เงินสด+โอน+บัตร'
    },
  ],
  'ledger_payment': [
    {
      key: 'CS',
      value: 'CS',
      text: 'เงินสด'
    }, {
      key: 'CD',
      value: 'CD',
      text: 'บัตร'
    }, {
      // key: 'TF',
      // value: 'TF',
      // text: 'โอน'
    }
  ],
  'status_bill': {
    N: 'ยังไม่เคลียร์บิล',
    Y: 'เคลียร์บิลแล้ว'
  },
  'kindTranfer': [
    {
      key: 0,
      value: 0,
      text: '- ทั้งหมด -',
    },
    {
      key: 1,
      value: 'TI',
      text: 'โอนเข้า',
    },
    {
      key: 2,
      value: 'TO',
      text: 'โอนออก',
    }
  ],
  'status_stock': {
    N: 'ยังไม่อัพเดทสต๊อก',
    Y: 'อัพเดทสต๊อกแล้ว',
    D: 'กำลังอัพเดทสต๊อก'
  },
  'status_bag': {
    O: 'เปิด',
    C: 'ปิด',
  },
  'kind_transfer': {
    TO: 'โอนออก',
    TI: 'โอนเข้า'
  },
  'user_role': [
    {
      key: 'S',
      value: 'S',
      text: 'Superadmin'
    }, {
      key: 'C',
      value: 'C',
      text: 'Accountant'
    },
    {
      key: 'A',
      value: 'A',
      text: 'Admin'
    },
    {
      key: 'M',
      value: 'M',
      text: 'Manager'
    },
    {
      key: 'U',
      value: 'U',
      text: 'User'
    }
  ],
  type_weight: [
    {
      key: 1,
      value: 1,
      text: 'บาท'
    }, {
      key: 2,
      value: 2,
      text: 'กรัม'
    }
  ],
  type_sale: [
    {
      key: 1,
      value: 1,
      text: 'งานชั่ง'
    }, {
      key: 2,
      value: 2,
      text: 'งานชิ้น'
    }
  ],
  weight: [
    {
      key: 0.125,
      value: 0.125,
      text: '1/2 สลึง'
    }, {
      key: 0.25,
      value: 0.25,
      text: '1 สลึง'
    }, {
      key: 0.50,
      value: 0.50,
      text: '2 สลึง'
    }, {
      key: 0.75,
      value: 0.75,
      text: '3 สลึง'
    }, {
      key: 1.5,
      value: 1.5,
      text: '6 สลึง'
    }, {
      key: 1.0,
      value: 1.0,
      text: '1 บ.'
    }, {
      key: 2.0,
      value: 2.0,
      text: '2 บ.'
    }, {
      key: 3.0,
      value: 3.0,
      text: '3 บ.'
    }, {
      key: 4.0,
      value: 4.0,
      text: '4 บ.'
    }, {
      key: 5.0,
      value: 5.0,
      text: '5 บ.'
    }, {
      key: 6.0,
      value: 6.0,
      text: '6 บ.'
    }, {
      key: 7.0,
      value: 7.0,
      text: '7 บ.'
    }, {
      key: 8.0,
      value: 8.0,
      text: '8 บ.'
    }, {
      key: 9.0,
      value: 9.0,
      text: '9 บ.'
    }, {
      key: 10.0,
      value: 10.0,
      text: '10 บ.'
    }
  ],
  ledger_kind: {
    'IN': 'รายรับ',
    'EX': 'รายจ่าย'
  },
  ledger_kind_option: [
    {
      key: 'IN',
      value: 'IN',
      text: 'รายรับ',
      content: <div ><Icon color='green' name='sticky note' />
        รายรับ</div>
    }, {
      key: 'EX',
      value: 'EX',
      text: 'รายจ่าย',
      content: <div ><Icon color='red' name='sticky note' />
        รายจ่าย</div>
    }
  ],
  status_lease: {
    '1': 'ใหม่',
    '2': 'ต่อดอก',
    '3': 'ไถ่คืน',
    '4': 'คัดออก'
  },
  status_lease_options: [
    {
      key: 1,
      value: 1,
      text: 'ใหม่'
    },
    {
      key: 2,
      value: 2,
      text: 'ต่อดอก'
    },
    {
      key: 3,
      value: 3,
      text: 'ไถ่คืน'
    },
    {
      key: 4,
      value: 4,
      text: 'คัดออก'
    }
  ],
  is_enabled_option: [
    {
      key: 1,
      value: 1,
      text: 'แสดง'
    },
    {
      key: 0,
      value: 0,
      text: 'ซ่อน'
    }],
  is_enabled_option_search: [{
    key: 2,
    value: 2,
    text: '--สถานะ--'
  },
  {
    key: 1,
    value: 1,
    text: 'แสดง'
  },
  {
    key: 0,
    value: 0,
    text: 'ซ่อน'
  }],
  is_active_option: [
    {
      key: 1,
      value: true,
      text: 'Active'
    },
    {
      key: 0,
      value: false,
      text: 'Inactive'
    }
  ]
};

export default Settings;
