/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form,
  Segment,
  Header,
  Icon,
  Grid,
  Menu
} from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import StockProduct from './StockProduct';
import StockCategory from './StockCategory'
import Transfer from './Transfer';
import ImportExportProduct from './ImportExportProduct';
import ImportBorrow from './ImportBorrow';
import ExportBorrow from './ExportBorrow';
import ExportCategory from './ExportCategory';
import ExportWaitMelt from './ExportWaitMelt/';
import StockWaitMelt from './StockWaitMelt';
import ExportProduct from './ExportProduct';
import BagOld from './BagOld';
import ImportGoldMelt from './ImportGoldMelt';
// import ImportBorrow from './ImportBorrow';
import TransferStock from './TransferStock';
import SplitHook from './SplitHook';
import { connect } from 'react-redux'
import LotMelt from './LotMelt';
import StockGold99 from './StockGold99';
import ExportGold99 from './ExportGold99';
import ReturnCashPawned from './ReturnCashPawned';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.location.pathname
    }

    this.handleItemClick = this
      .handleItemClick
      .bind(this);
  }

  handleItemClick(e, { name, to }) {
    this.setState({ activeItem: to });
  }

  render() {
    const {
      rowIndex,
      field,
      data,
      auth,
    } = this.props;
    return (
      <div>
        <Form size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <Header floated='left' as='h4'><Icon name='shipping' />
                สต็อกทอง</Header>
            </Form.Field>
          </Form.Group>
        </Form>
        <Router>

          <Grid>
            <Grid.Column width={3}>
              <Segment color='black'>

                <Menu secondary vertical className='menu-left'>
                  <Menu.Item>
                    <Menu.Header>ทองใหม่</Menu.Header>
                    <Menu.Menu>
                      <Menu.Item
                        id='importStock'
                        name='import'
                        active={this.state.activeItem == '/stock/import'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/import'>
                        นำเข้าทองใหม่
                      </Menu.Item>
                      {auth.role == 'A' || auth.role == 'S' ?
                        <Menu.Item
                          id='exportStock'
                          name='export'
                          active={this.state.activeItem == '/stock/export'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/export'>
                          นำออกทองใหม่
                        </Menu.Item>
                        : ''}
                      <Menu.Item
                        id='transferStock'
                        name='transfer'
                        active={this.state.activeItem == '/stock/transfer'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/transfer'>
                        โอนทองใหม่ระหว่างสาขา
                      </Menu.Item>
                      <Menu.Item
                        id='newStock'
                        name='product'
                        active={this.state.activeItem == '/stock/product'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/product'>
                        สต็อกทองใหม่
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu.Item>
                  <Menu.Item>
                    <Menu.Header>ทองยืม</Menu.Header>
                    <Menu.Menu>
                      <Menu.Item
                        id='importBorrow'
                        name='import_borrow'
                        active={this.state.activeItem == '/stock/import_borrow'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/import_borrow'>
                        นำเข้า
                      </Menu.Item>
                      <Menu.Item
                        id='exportBorrow'
                        name='export_borrow'
                        active={this.state.activeItem == '/stock/export_borrow'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/export_borrow'>
                        นำออก
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu.Item>
                  <Menu.Item>
                    <Menu.Header>ทองเก่า</Menu.Header>
                    <Menu.Menu>
                      <Menu.Item
                        id='bag_old'
                        name='bag_old'
                        active={this.state.activeItem == '/stock/bag_old'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/bag_old'>
                        ห่อทองเก่า
                      </Menu.Item>
                      <Menu.Item
                        id='exportOldStock'
                        name='export_old'
                        active={this.state.activeItem == '/stock/export_old'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/export_old'>
                        นำออกทองเก่า
                      </Menu.Item>

                      <Menu.Item
                        id='transferStock'
                        name='transfer_stock'
                        active={this.state.activeItem == '/stock/transfer_stock'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/transfer_stock'>
                        โอนทองเก่าเข้าสต็อกทองใหม่
                      </Menu.Item>

                      <Menu.Item
                        id='transferStock'
                        name='transfer_stock'
                        active={this.state.activeItem == '/stock/split_hook'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/split_hook'>
                        แยกตะขอ
                      </Menu.Item>

                      <Menu.Item
                        id='oldStock'
                        name='category'
                        active={this.state.activeItem == '/stock/category'}
                        onClick={this.handleItemClick}
                        as={Link}
                        to='/stock/category'>
                        สต็อกทองเก่า
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu.Item>
                  {auth.role == 'A' || auth.role == 'S' ?
                    <Menu.Item>
                      <Menu.Header>ทองเก่ารอหลอม</Menu.Header>
                      <Menu.Menu>
                        <Menu.Item
                          id='exportWaitMeltStock'
                          name='export_wait_melt'
                          active={this.state.activeItem == '/stock/export_wait_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/export_wait_melt'>
                          นำออกทองเก่าไปหลอม
                        </Menu.Item>

                        <Menu.Item
                          id='waitMeltStock'
                          name='wait_melt'
                          active={this.state.activeItem == '/stock/wait_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/wait_melt'>
                          สต็อกทองเก่ารอหลอม
                        </Menu.Item>
                        <Menu.Item
                          id='lotMeltStock'
                          name='lot_melt'
                          active={this.state.activeItem == '/stock/lot_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/lot_melt'>
                          รวมบิลส่งหลอม
                        </Menu.Item>
                      </Menu.Menu>
                    </Menu.Item>
                    : ''}
                  {auth.role == 'A' || auth.role == 'S' ?
                    <Menu.Item>
                      <Menu.Header>ทอง 99.99</Menu.Header>
                      <Menu.Menu>
                        <Menu.Item
                          id='importGold99'
                          name='import_gold_melt'
                          active={this.state.activeItem == '/stock/import_gold_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/import_gold_melt'>
                          นำเข้า
                        </Menu.Item>
                        <Menu.Item
                          id='exportGold99'
                          name='export_gold_99'
                          active={this.state.activeItem == '/stock/export_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/export_gold_99'>
                          นำออก
                        </Menu.Item>
                        <Menu.Item
                          id='stockGold99'
                          name='stock_gold_99'
                          active={this.state.activeItem == '/stock/gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/gold_99'>
                          สต็อกทอง 99.99
                        </Menu.Item>
                      </Menu.Menu>
                    </Menu.Item>
                    : ''}
                  {auth.role == 'A' || auth.role == 'S' ?
                    <Menu.Item>
                      <Menu.Header>เงินสด</Menu.Header>
                      <Menu.Menu>
                        <Menu.Item
                          name='return_cash_pawned'
                          active={this.state.activeItem == '/stock/return_cash_pawned'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/stock/return_cash_pawned'>
                          คืนเงินทองหลุดจำนำ
                        </Menu.Item>
                      </Menu.Menu>
                    </Menu.Item>
                    : ''}
                </Menu>
              </Segment>
            </Grid.Column>
            <Grid.Column width={13}>
              <Segment color='black'>
                <Route path="/stock/import" component={() => (<ImportExportProduct action="import" />)} />
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/export" component={() => (<ExportProduct />)} /> : ''}
                <Route path="/stock/transfer" component={() => (<Transfer />)} />
                <Route path="/stock/bag_old" component={() => (<BagOld />)} />
                <Route path="/stock/export_old" component={() => (<ExportCategory />)} />
                <Route path="/stock/transfer_stock" component={() => (<TransferStock />)} />
                <Route path="/stock/split_hook" component={() => (<SplitHook />)} />
                <Route path="/stock/product" component={() => (<StockProduct />)} />
                <Route path="/stock/category" component={() => (<StockCategory />)} />
                <Route path="/stock/import_borrow" component={() => (<ImportBorrow />)} />
                <Route path="/stock/export_borrow" component={() => (<ExportBorrow />)} />
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/export_wait_melt" component={() => (<ExportWaitMelt />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/wait_melt" component={() => (<StockWaitMelt />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/import_gold_melt" component={() => (<ImportGoldMelt />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/lot_melt" component={() => (<LotMelt />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/gold_99" component={() => (<StockGold99 />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/export_gold_99" component={() => (<ExportGold99 />)} /> : ''}
                {auth.role == 'A' || auth.role == 'S' ? <Route path="/stock/return_cash_pawned" component={() => (<ReturnCashPawned />)} /> : ''}
              </Segment>
            </Grid.Column>
          </Grid>

        </Router>
      </div>
    );
  }
}
const mapStateToProps = state => {

  return ({
    auth: state.auth
  })
}
export default connect(
  mapStateToProps
)(Main)
