/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Link,
} from 'react-router-dom';

import {
  Menu,
  Grid,
  Segment,
  Accordion, Icon, Form, Header
} from 'semantic-ui-react';

import { connect } from 'react-redux'

import CustomerReportPreview from './POS/CustomerReportPreview';
import MobileRegisReportPreview from './POS/MobileRegisReportPreview'
import ScoreReportPreview from './POS/ScoreReportPreview'
import RedeemPointReportPreview from './POS/RedeemPointReportPreview'
import SellGroupPrintPreview from './POS/SellGroupPrintPreview'
import SalesPrintPreview from './POS/SalesPrintPreview'
import SellPrintPreview from './POS/SellPrintPreview'
import SellTypePrintPreview from './POS/SellTypePrintPreview'
import BuyPrintPreview from './POS/BuyPrintPreview'
import ExcPrintPreview from './POS/ExcPrintPreview'
import SellTypeAmountPrintPreview from './POS/SellTypeAmountPrintPreview'
import StockInPrintPreview from './Stock/StockInPrintPreview'
import StockEXPrintPreview from './Stock/StockEXPrintPreview'
import StockCategoryEXPrintPreview from './Stock/StockCategoryEXPrintPreview'
import StockSellBuyPrintPreview from './Stock/StockSellBuyPrintPreview'
import StokcDailyPrintPreview from './Stock/StokcDailyPrintPreview'
import StokcDaily2PrintPreview from './Stock/StokcDaily2PrintPreview'
import StockBorrowPrintPreview from './Stock/StockBorrowPrintPreview'
import StockAAPrintPreview from './Stock/StockAAPrintPreview'
import StockBorrowDiffPrintPreview from './Stock/StockBorrowDiffPrintPreview'
import StockNewGoldPrintPreview from './Stock/StockNewGoldPrintPreview';
import StockOldGoldReportPrintPreview from './Stock/StockOldGoldReportPrintPreview';
import StockTypeAmountPrintPreview from './Stock/StockTypeAmountPrintPreview'
import StockTypeAndCodePrintPreview from './Stock/StockTypeAndCodePrintPreview'
import StockVolumePrintPreview from './Stock/StockVolumePrintPreview'
import StockMotionPrintPreview from './Stock/StockMotionPrintPreview'
import StockNotMotionPrintPreview from './Stock/StockNotMotionPrintPreview'
import LeasePrintPreview from './Lease/LeasePrintPreview'
import LeaseInterestPrintPreview from './Lease/LeaseInterestPrintPreview'
import LeaseRedeemPrintPreview from './Lease/LeaseRedeemPrintPreview'
import LeaseEjectPrintPreview from './Lease/LeaseEjectPrintPreview'
import LeaseDuePrintPreview from './Lease/LeaseDuePrintPreview'
import LeaseOverduePrintPreview from './Lease/LeaseOverduePrintPreview'
import LeaseMonthPrintPreview from './Lease/LeaseMonthPrintPreview'
import LeaseGroupMonthPrintPreview from './Lease/LeaseGroupMonthPrintPreview'
import LeaseAllPrintPreview from './Lease/LeaseAllPrintPreview'
import LeaseStockPrintPreview from './Lease/LeaseStockPrintPreview'
import LeasePrinciplePrintPreview from './Lease/LeasePrinciplePrintPreview'
import LeaseInterestHistoryPrintPreview from './Lease/LeaseInterestHistoryPrintPreview'
import Daily from './Conclude/Daily'
import LedgerDaily from './Conclude/LedgerDaily'
import LedgerPrintPreview from './Conclude/LedgerPrintPreview'
import LedgerKindPrintPreview from './Conclude/LedgerKindPrintPreview'
import LedgerCardPrintPreview from './Conclude/LedgerCardPrintPreview'
import LedgerCheckPrintPreview from './Conclude/LedgerCheckPrintPreview'
import LedgerVoidPrintPreview from './Conclude/LedgerVoidPrintPreview'
import SellPrintPreview2 from './Conclude/SellPrintPreview'
import StockTransferRejected from './Stock/StockTransferRejectedPrintPreview'
import StockTransferCancelled from './Stock/StockTransferCancelledPrintPreview'
// savings report
import SavingPrintPreview from './Savings/SavingPrintPreview'
import StatementPrintPreview from './Savings/StatementPrintPreview'
import SavingOnDatePrintPreview from './Savings/SavingOnDatePrintPreview'
import SavingOpeningPrintPreview from './Savings/SavingOpeningPrintPreview'
import SavingDepositWithdrawPrintPreview from './Savings/SavingDepositWithdrawPrintPreview'
import SavingSummaryPrintPreview from './Savings/SavingSummaryPrintPreview'

// account report
import BuyAverage from './account/BuyAverage'
import SellAverage from './account/SellAverage'
import MotionStock from './account/MotionStock'
import ExcAccPrintPreview from './account/ExcAccPrintPreview'
import DefAccPrintPreview from './account/DefAccPrintPreview'
import BuyAccPrintPreview from './account/BuyAccPrintPreview'
import EjectAccPrintPreview from './account/EjectAccPrintPreview'
import StockOldProductItem from './account/StockOldProductItem'

import VoidBillPrintPreview from './POS/VoidBillPrintPreview'
import LeaseCancelPrintPreview from './Lease/LeaseCancelPrintPreview'
import InterestCancelPrintPreview from './Lease/InterestCancelPrintPreview'
import SavingCancelPrintPreview from './Savings/SavingCancelPrintPreview'

import VatPrintPreview from "./account/VatPrintPreview"
import ExchangePrintPreview from "./account/ExchangePrintPreview"
import StockPrintPreview from "./account/StockPrintPreview"
import WaitMeltPrintPreview from './account/WaitMeltPrintPreview'
import SellAccountPrintPreview from "./account/SellAccountPrintPreview"
import CommissionPrintPreview from "./account/CommissionPrintPreview"
import StockInAccountPrintPreview from './account/StockInAccountPrintPreview';

import RedeemPrintPreview from './Reward/RedeemPrintPreview'

import ExchangeCostPrintPreview from "./SuperAdmin/ExchangePrintPreview.js"
import BuyGoldPrintPreview from "./SuperAdmin/BuyGoldPrintPreview"
import WeightDiffPrintPreview from './SuperAdmin/WeightDiffPrintPreview'
import FeeCostPrintPreview from "./SuperAdmin/FeeCostPrintPreview"
import SellGoldPrintPreview from './SuperAdmin/SellGoldPrintPreview'
import ExchangeGoldPrintPreview from './SuperAdmin/ExchangeGoldPrintPreview'
import ProfitLossFromExchangeGoldPrintPreview from './SuperAdmin/ProfitLossFromExchangeGoldPrintPreview'
import SavingGoldPrintPreview from './SuperAdmin/SavingGoldPrintPreview'
import LeaseInterestOutPrintPreview from './SuperAdmin/LeaseInterestOutPrintPreview'
import StockLeasePrintPreview from './SuperAdmin/StockLeasePrintPreview'
import StockOldGoldPrintPreview from './SuperAdmin/StockOldGoldPrintPreview'
import LeaseCutPrintPreview from './SuperAdmin/LeaseCutPrintPreview'
import StockNewGoldWeightPrintPreview from './SuperAdmin/StockNewGoldWeightPrintPreview'
import StockNewGoldPiecePrintPreview from './SuperAdmin/StockNewGoldPiecePrintPreview'
import BranchGradingPrintPreview from './SuperAdmin/BranchGradingPrintPreview'

import RewardPrintPreview from "./Reward/RewardPrintPreview"

import CheckVatPrintPreview from "./account/CheckVatPrintPreview"
import SummaryLeasePrintPreview from "./account/SummaryLeasePrintPreview"

import StockLeasePrintPreviews from "./Lease/StockLeasePrintPreview"

import StaffProductStockPrintPreview from './POS/StaffProductStockPrintPreview'

import LeaseByBillPrintPreview from './Lease/LeaseByBillPrintPreview'
import StockLeaseAllBranchPrintPreview from "./Lease/StockLeaseAllBranchPrintPreview"
import StockReadyReportPreview from "./StockReady/StockReadyReportPreview"
import StockConsignmentReport from "./Lease/StockConsignmentReport"

import SellNewGoldReport from './POS/SellNewGoldReport';
import ImportGold99PrintPreview from './account/ImportGold99PrintPreview';
import OldGoldMeltPrintPreview from './account/OldGoldMeltPrintPreview';
import DifferenceGoldMeltPrintPreview from './account/DifferenceGoldMeltPrintPreview';
import SummaryBranchReport from './Conclude/SummaryBranchReport';
import LedgerTransferPrintPreview from './Conclude/LedgerTransferPrintPreview';
import SummaryLedgerTransferPrintPreview from './account/SummaryLedgerTransferPrintPreview';
import OverviewReport from './account/OverviewReport';
import ExportGold99PrintPreview from './SuperAdmin/ExportGold99PrintPreview';
import StockGold99PrintPreview from './SuperAdmin/StockGold99PrintPreview';
import OverviewSaleChangePrintPreview from './SuperAdmin/OverviewSaleChangePrintPreview';

import RewardSummaryPrintPreview from './Reward/RewardSummaryPrintPreview.js';
import SafeSummaryReport from './Conclude/SafeSummaryPrintPreview.js'
import SafeStatementReport from './Conclude/SafeStatementPrintPreview.js'
import CashStatementReport from './Conclude/CashStatementPrintPreview.js'
import LeaseIncome from './account/LeaseIncome.js';
import LeaseSummaryIncome from './account/LeaseSummaryIncome.js';
import config from '../../config/index.js';

import NewCustomer from './POS/NewCustomerPrintPreview.js'

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: this.props.location.pathname,
      activeIndex: -1
    }

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  state = {
    activeItem: '/report/ledger'
  };

  handleItemClick(e, { name, to }) {
    this.setState({
      activeItem: to
    });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  componentDidMount() {

    let elHeight = document.getElementById('content-body')

    this.setState({
      clientWidth: window.innerWidth
    })
  }
  render() {
    const { auth } = this.props
    const { activeIndex } = this.state
    const { role } = auth
    let clientWidth = this.state.clientWidth
    return (<div>
      <Form size='small'>
        <Form.Group>
          <Form.Field width={7}>
            <Header floated='left' as='h3'>
              <div style={{ padding: '7px' }} className="pull-left"> <Icon name='bar chart' /> รายงาน </div> </Header>
          </Form.Field>

        </Form.Group>
      </Form>
      <Router>
        <div>
          <Grid>
            <Grid.Column width={3}>

              <Segment color='black' style={{
                height: '90vh',
                overflow: 'auto'
              }}>

                <Accordion>


                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='money' /> ซื้อ-ขาย
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='sell'
                          active={this.state.activeItem === '/report/sell_group'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_group'
                        >รายงานขายทองรูปพรรณ/ทองแท่ง</Menu.Item>
                        <Menu.Item
                          name='sell'
                          active={this.state.activeItem === '/report/sell'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell'
                        >รายงานการขายทอง</Menu.Item>
                        <Menu.Item
                          name='def_acc'
                          active={this.state.activeItem === '/report/def_acc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/def_acc'
                        >รายงานทองชำรุด
                        </Menu.Item>
                        <Menu.Item
                          name='bill'
                          active={this.state.activeItem === '/report/sell_type'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_type'
                        >รายงานการขายแยกตามประเภทและน้ำหนัก</Menu.Item>
                        <Menu.Item
                          name='bill'
                          active={this.state.activeItem === '/report/buy'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/buy'
                        >รายงานซื้อทองเก่า</Menu.Item>
                        <Menu.Item
                          name='bill'
                          active={this.state.activeItem === '/report/exc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exc'
                        >รายงานเปลี่ยนทอง</Menu.Item>
                        <Menu.Item
                          name='bill'
                          active={this.state.activeItem === '/report/sell_type_amount'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_type_amount'
                        >รายงานจำนวนชิ้นขาย</Menu.Item>
                        <Menu.Item
                          name='staff_sale'
                          active={this.state.activeItem === '/report/staff_sale'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/staff_sale'
                        >รายงานพนักงานซื้อทอง
                        </Menu.Item>
                        <Menu.Item
                          name='sell_new_gold'
                          active={this.state.activeItem === '/report/sell_new_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_new_gold'
                        >รายงานขายทองใหม่
                        </Menu.Item>
                      </Menu>

                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='shipping' /> สต็อกทอง
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='stock_im'
                          active={this.state.activeItem === '/report/stock_im'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_im'
                        >รายงานการนำทองเข้าสต็อก</Menu.Item>
                        <Menu.Item
                          name='stock_ex'
                          active={this.state.activeItem === '/report/stock_ex'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_ex'
                        >รายงานการนำทองออกจากสต็อก</Menu.Item>
                        <Menu.Item
                          name='stock_category_ex'
                          active={this.state.activeItem === '/report/stock_category_ex'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_category_ex'
                        >รายงานการตัดทองเก่า</Menu.Item>
                        <Menu.Item
                          name='stock_daily2'
                          active={this.state.activeItem === '/report/stock_daily2'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_daily2'
                        >รายงานสต็อกสินค้า</Menu.Item>
                        <Menu.Item
                          name='stock_sell_buy'
                          active={this.state.activeItem === '/report/stock_sell_buy'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_sell_buy'
                        >รายงานทองเข้า-ทองออก</Menu.Item>
                        <Menu.Item
                          name='stock_daily'
                          active={this.state.activeItem === '/report/stock_daily'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_daily'
                        >รายงานสต็อกทองคงเหลือตามวันที่</Menu.Item>
                        <Menu.Item
                          name='stock_type_and_code'
                          active={this.state.activeItem === '/report/stock_type_and_code'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_type_and_code'
                        >รายงานสต็อกแยกตามประเภท</Menu.Item>
                        <Menu.Item
                          name='stock_type_amount'
                          active={this.state.activeItem === '/report/stock_type_amount'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_type_amount'
                        >รายงานจำนวนชิ้นในสต็อก</Menu.Item>
                        <Menu.Item
                          name='stock_volume'
                          active={this.state.activeItem === '/report/stock_volume'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_volume'
                        >รายงานปริมาณสินค้าในสต็อก</Menu.Item>
                        <Menu.Item
                          name='stock_motion'
                          active={this.state.activeItem === '/report/stock_motion'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_motion'
                        >รายงานสินค้าไม่เคลื่อนไหว</Menu.Item>
                        <Menu.Item
                          name='stock_not_motion'
                          active={this.state.activeItem === '/report/stock_not_motion'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_not_motion'
                        >รายงานการเคลื่อนไหวของสินค้า</Menu.Item>
                        <Menu.Item
                          name='stock_borrow'
                          active={this.state.activeItem === '/report/stock_borrow'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_borrow'
                        >รายงานสต็อกสินค้ายืมคงเหลือ</Menu.Item>
                        <Menu.Item
                          name='stock_aa'
                          active={this.state.activeItem === '/report/stock_aa'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_aa'
                        >รายงานสต็อกสินค้า AA (ไม่รวมสต็อกยืม)</Menu.Item>
                        <Menu.Item
                          name='stock_borrow_diff'
                          active={this.state.activeItem === '/report/stock_borrow_diff'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_borrow_diff'
                        >รายงานส่วนต่างสต็อกยืม</Menu.Item>
                        <Menu.Item
                          name='stock_new_gold'
                          active={this.state.activeItem === '/report/stock_new_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_new_gold'
                        >รายงานสรุปสต็อกทองใหม่รวมทุกสาขา</Menu.Item>
                        <Menu.Item
                          name='stock_old_gold_report'
                          active={this.state.activeItem === '/report/stock_old_gold_report'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_old_gold_report'
                        >รายงานสรุปมูลค่าสต็อกทองเก่า</Menu.Item>


                      </Menu>
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='add to calendar' />ขายฝาก
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='lease'
                          active={this.state.activeItem === '/report/lease'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease'
                        >รายงานยอดขายฝาก</Menu.Item>
                        <Menu.Item
                          name='lease_interest'
                          active={this.state.activeItem === '/report/lease_interest'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_interest'
                        >รายงานยอดดอกเบี้ย(ต่อดอก)</Menu.Item>
                        <Menu.Item
                          name='redeem'
                          active={this.state.activeItem === '/report/lease_redeem'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_redeem'
                        >รายงานยอดไถ่คืน</Menu.Item>
                        <Menu.Item
                          name='lease_eject'
                          active={this.state.activeItem === '/report/lease_eject'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_eject'
                        >รายงานยอดคัดออก</Menu.Item>
                        <Menu.Item
                          name='lease_due'
                          active={this.state.activeItem === '/report/lease_due'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_due'
                        >รายงานยอดครบกำหนด</Menu.Item>
                        <Menu.Item
                          name='lease_overdue'
                          active={this.state.activeItem === '/report/lease_overdue'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_overdue'
                        >รายงานเกินกำหนด</Menu.Item>
                        <Menu.Item
                          name='lease_mounth'
                          active={this.state.activeItem === '/report/lease_mounth'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_mounth'
                        >รายงานยอดขายฝาก,ดอกเบี้ย,รายเดือนตามปีที่ระบุ</Menu.Item>
                        <Menu.Item
                          name='lease_group_mounth'
                          active={this.state.activeItem === '/report/lease_group_mounth'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_group_mounth'
                        >รายงานยอดขายฝาก,ดอกเบี้ย,รายเดือน</Menu.Item>
                        <Menu.Item
                          name='lease_all'
                          active={this.state.activeItem === '/report/lease_all'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_all'
                        >สรุปรายงานขายฝาก</Menu.Item>

                        <Menu.Item
                          name='lease_stock'
                          active={this.state.activeItem === '/report/lease_stock'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_stock'
                        >รายงานสินค้าขายฝาก</Menu.Item>

                        <Menu.Item
                          name='stock_leases'
                          active={this.state.activeItem === '/report/stock_leases'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_leases'
                        >รายงานสรุปสต็อกขายฝาก ณ วันที่</Menu.Item>

                        <Menu.Item
                          name='lease_principle'
                          active={this.state.activeItem === '/report/lease_principle'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_principle'
                        >รายงานประวัติการเพิ่มลดเงินต้น</Menu.Item>
                        <Menu.Item
                          name='lease_history'
                          active={this.state.activeItem === '/report/lease_history'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_history'
                        >รายงานประวัติการต่อดอก</Menu.Item>
                        <Menu.Item
                          name='lease_by_bill'
                          active={this.state.activeItem === '/report/lease_by_bill'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_by_bill'
                        >รายงานสต็อกขายฝากรายบิล</Menu.Item>
                         <Menu.Item
                          name='stock_lease_all_branch'
                          active={this.state.activeItem === '/report/stock_lease_all_branch'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_lease_all_branch'
                        >รายงานสต็อกสรุปรวมทุกสาขา ณ วันที่</Menu.Item>
                        <Menu.Item
                          name='stock_consignment'
                          active={this.state.activeItem === '/report/stock_consignment'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_consignment'
                        >รายงานรายได้จากการขายฝาก</Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='save' />ออมทอง
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='saving'
                          active={this.state.activeItem === '/report/saving'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/saving'
                        >รายงานบัญชีเงินฝาก</Menu.Item>
                        <Menu.Item
                          name='saving_open'
                          active={this.state.activeItem === '/report/saving_open'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/saving_open'
                        >รายงานเปิดบัญชี</Menu.Item>
                        {/* <Menu.Item
                      name='saving_close'
                      active={this.state.activeItem === '/report/saving_close'}
                      onClick={this.handleItemClick}
                      as={Link}
                      to='/report/saving_close'
                    >รายงานปิดบัญชี</Menu.Item> */}
                        <Menu.Item
                          name='saving_deposit_withdraw'
                          active={this.state.activeItem === '/report/saving_deposit_withdraw'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/saving_deposit_withdraw'
                        >รายงานฝากถอนประจำวัน</Menu.Item>
                        <Menu.Item
                          name='statement'
                          active={this.state.activeItem === '/report/statement'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/statement'
                        >รายงานธุรกรรมแยกรายบุคคล</Menu.Item>
                        <Menu.Item
                          name='statement'
                          active={this.state.activeItem === '/report/saving_balance_on_date'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/saving_balance_on_date'
                        >รายงานยอดคงเหลือ ณ วันที่</Menu.Item>
                        <Menu.Item
                          name='summary-balance'
                          active={this.state.activeItem === '/report/summary-balance'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/summary-balance'
                        >รายงานสรุปออมทองรวมทุกสาขา</Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 4}
                    index={4}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='calendar check outline' />
                    รายงานสรุป
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 4}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='daily'
                          active={this.state.activeItem === '/report/daily'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/daily'
                        >รายงานสรุปประจำวัน</Menu.Item>

                        <Menu.Item
                          name='ledger_daily'
                          active={this.state.activeItem === '/report/ledger_daily'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_daily'
                        >รายงานรายรับ-รายจ่ายประจำวัน</Menu.Item>
                        <Menu.Item
                          name='ledger_list'
                          active={this.state.activeItem === '/report/ledger_list'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_list'
                        >รายงานบิลรับเงิน-จ่ายเงิน</Menu.Item>
                        <Menu.Item
                          name='sell'
                          active={this.state.activeItem === '/report/sales'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sales'
                        >รายงานยอดขาย</Menu.Item>
                        <Menu.Item
                          name='ledger_kind'
                          active={this.state.activeItem === '/report/ledger_kind'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_kind'
                        >รายงานบิลรับเงิน-จ่ายเงินแบ่งตามประเภท</Menu.Item>
                        <Menu.Item
                          name='ledger_card'
                          active={this.state.activeItem === '/report/ledger_card'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_card'
                        >รายงานการชำระเงินแบบเครดิต</Menu.Item>
                        <Menu.Item
                          name='ledger_transfer'
                          active={this.state.activeItem === '/report/ledger_transfer'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_transfer'
                        >รายงานการชำระเงินแบบโอน</Menu.Item>
                        {/* <Menu.Item
                      name='ledger_check'
                      active={this.state.activeItem === '/report/ledger_check'}
                      onClick={this.handleItemClick}
                      as={Link}
                      to='/report/ledger_check'
                    >การชำระเงินแบบเช็ค</Menu.Item> */}
                        <Menu.Item
                          name='ledger_sell'
                          active={this.state.activeItem === '/report/ledger_sell'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/ledger_sell'
                        >สรุปยอดขายทองรวม</Menu.Item>
                        <Menu.Item
                          name='ledger_sell'
                          active={this.state.activeItem === '/report/summary-branch'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/summary-branch'
                        >รายงานสรุปผลการขาย</Menu.Item>
                        <Menu.Item
                          name='safe-summary'
                          active={this.state.activeItem === '/report/safe-summary'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/safe-summary'
                        >รายงานสรุปกระแสเงินสดสาขา ณ วันที่</Menu.Item>
                        <Menu.Item
                          name='safe-statement'
                          active={this.state.activeItem === '/report/safe-statement'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/safe-statement'
                        >รายงาน Statement เงินสดในเซฟ</Menu.Item>
                        <Menu.Item
                          name='cash-statement'
                          active={this.state.activeItem === '/report/cash-statement'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/cash-statement'
                        >รายงานธุรกรรมเงินสด</Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>

                  {['S', 'C'].includes(role) ? <Accordion.Title
                    active={activeIndex === 5}
                    index={5}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='file alternate' />
                    รายงานบัญชี
                  </Accordion.Title> : null}
                  <Accordion.Content active={activeIndex === 5}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='buy_average'
                          active={this.state.activeItem === '/report/buy_average'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/buy_average'
                        >รายงานราคาต้นทุนถัวเฉลี่ย
                        </Menu.Item>
                        <Menu.Item
                          name='sell_average'
                          active={this.state.activeItem === '/report/sell_average'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_average'
                        >รายงานราคาขายและเปลี่ยนเฉลี่ย
                        </Menu.Item>
                        <Menu.Item
                          name='motion_stock'
                          active={this.state.activeItem === '/report/motion_stock'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/motion_stock'
                        >รายงานสินค้าคงเหลือ(แบบเคลื่อนไหว)
                        </Menu.Item>
                        <Menu.Item
                          name='buy_acc'
                          active={this.state.activeItem === '/report/buy_acc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/buy_acc'
                        >รายงานรับซื้อทองเก่าหน้าร้าน
                        </Menu.Item>
                        <Menu.Item
                          name='exc_acc'
                          active={this.state.activeItem === '/report/exc_acc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exc_acc'
                        >รายงานทองเปลี่ยน
                        </Menu.Item>
                        <Menu.Item
                          name='def_acc'
                          active={this.state.activeItem === '/report/def_acc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/def_acc'
                        >รายงานทองชำรุด
                        </Menu.Item>
                        <Menu.Item
                          name='eject_acc'
                          active={this.state.activeItem === '/report/eject_acc'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/eject_acc'
                        >รายงานทองหลุดจำนำ
                        </Menu.Item>
                        <Menu.Item
                          name='stock_old_product_item'
                          active={this.state.activeItem === '/report/stock_old_product_item'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_old_product_item'
                        >รายงานสต็อกทองเก่าคงเหลือ (แบบเคลื่อนไหว)</Menu.Item>
                        <Menu.Item
                          name='sellaccount'
                          active={this.state.activeItem === '/report/sellaccount'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sellaccount'
                        >รายงานขายทอง</Menu.Item>
                        <Menu.Item
                          name='vat'
                          active={this.state.activeItem === '/report/vat'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/vat'
                        >รายงานภาษีขาย</Menu.Item>
                        <Menu.Item
                          name='vat'
                          active={this.state.activeItem === '/report/checkvat'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/checkvat'
                        >รายงานตรวจสอบยอดภาษี</Menu.Item>
                        <Menu.Item
                          name='exchange'
                          active={this.state.activeItem === '/report/exchange'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exchange'
                        >รายงานเปลี่ยนทอง</Menu.Item>
                        <Menu.Item
                          name='stock'
                          active={this.state.activeItem === '/report/stock'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock'
                        >รายงานสต็อกสินค้า</Menu.Item>
                        <Menu.Item
                          name='wait_melt'
                          active={this.state.activeItem === '/report/wait_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/wait_melt'
                        >รายงานส่งหลอม</Menu.Item>
                        <Menu.Item
                          name='commission'
                          active={this.state.activeItem === '/report/commission'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/commission'
                        >รายงานค่าคอมมิชชั่นรวม</Menu.Item>
                        <Menu.Item
                          name='stock_im_account'
                          active={this.state.activeItem === '/report/stock_im_account'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_im_account'
                        >รายงานการนำทองเข้าสต็อก</Menu.Item>
                        <Menu.Item
                          name='summary_lease'
                          active={this.state.activeItem === '/report/summary_lease'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/summary_lease'
                        >รายงานสรุปยอดดอกเบี้ย(ต่อดอก)รายสาขา</Menu.Item>
                        <Menu.Item
                          name='old_gold_melt'
                          active={this.state.activeItem === '/report/old_gold_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/old_gold_melt'
                        >รายงานแยกประเภททองเก่าส่งหลอม</Menu.Item>
                        <Menu.Item
                          name='import_gold_99'
                          active={this.state.activeItem === '/report/import_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/import_gold_99'
                        >รายงานการนำเข้าทอง99.99% เข้าสต็อก</Menu.Item>
                        <Menu.Item
                          name='stock_gold_99'
                          active={this.state.activeItem === '/report/stock_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_gold_99'
                        >รายงานสต็อกทอง 99.99
                        </Menu.Item>
                        <Menu.Item
                          name='export_gold_99'
                          active={this.state.activeItem === '/report/export_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/export_gold_99'
                        >รายงานนำทอง 99.99 ออกจากสต็อก
                        </Menu.Item>
                        <Menu.Item
                          name='difference_gold_melt'
                          active={this.state.activeItem === '/report/difference_gold_melt'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/difference_gold_melt'
                        >รายงานส่วนต่างทองเก่า-หลอมแล้ว</Menu.Item>
                        <Menu.Item
                          name='summary-ledger-transfer'
                          active={this.state.activeItem === '/report/summary-ledger-transfer'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/summary-ledger-transfer'
                        >รายงานสรุปเงินโอน</Menu.Item>
                        <Menu.Item
                          name='overview'
                          active={this.state.activeItem === '/report/overview'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/overview'
                        >รายงานสรุปภาพรวม</Menu.Item>
                        <Menu.Item
                          name='lease-income'
                          active={this.state.activeItem === '/report/lease-income'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease-income'
                        >รายงานจำแนกรายรับขายฝาก</Menu.Item>
                        <Menu.Item
                          name='lease-summary-income'
                          active={this.state.activeItem === '/report/lease-summary-income'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease-summary-income'
                        >รายงานสรุปจำแนกรายรับขายฝาก</Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>
                  {['S'].includes(role) ? <Accordion.Title
                    active={activeIndex === 6}
                    index={6}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='file alternate' />
                    รายงาน superadmin
                  </Accordion.Title> : null}
                  <Accordion.Content active={activeIndex === 6}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='stock_transfer_rejected'
                          active={this.state.activeItem === '/report/stock_transfer_rejected'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_transfer_rejected'
                        >รายงานใบโอนออกทองใหม่ระหว่างสาขาถูก reject
                        </Menu.Item>
                        <Menu.Item
                          name='stock_transfer_cancelled'
                          active={this.state.activeItem === '/report/stock_transfer_cancelled'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_transfer_cancelled'
                        >รายงานยกเลิกโอนออกทองใหม่ระหว่างสาขา
                        </Menu.Item>
                        <Menu.Item
                          name='void_bill'
                          active={this.state.activeItem === '/report/void_bill'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/void_bill'
                        >รายงานยกเลิกบิลซื้อขายเปลี่ยนและชำรุด
                        </Menu.Item>
                        <Menu.Item
                          name='cancel_lease'
                          active={this.state.activeItem === '/report/cancel_lease'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/cancel_lease'
                        >รายงานยกเลิกใบขายฝาก/คัดออก
                        </Menu.Item>
                        <Menu.Item
                          name='cancel_interest'
                          active={this.state.activeItem === '/report/cancel_interest'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/cancel_interest'
                        >รายงานยกเลิกดอกเบี้ยรับ/ไถ่คืน
                        </Menu.Item>
                        <Menu.Item
                          name='void_ledger'
                          active={this.state.activeItem === '/report/void_ledger'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/void_ledger'
                        >รายงานยกเลิกบิลรายรับ-รายจ่าย
                        </Menu.Item>
                        <Menu.Item
                          name='void_ledger'
                          active={this.state.activeItem === '/report/cancel_saving'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/cancel_saving'
                        >รายงานยกเลิกออมทอง
                        </Menu.Item>
                        <Menu.Item
                          name='branch_grading'
                          active={this.state.activeItem === '/report/branch_grading'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/branch_grading'
                        >รายงานการให้คะแนนสาขา
                        </Menu.Item>

                      </Menu>
                    </p>
                  </Accordion.Content>

                  {['S'].includes(role) ? <Accordion.Title
                    active={activeIndex === 8}
                    index={8}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='file alternate' />
                    รายงานผู้บริหาร
                  </Accordion.Title> : null}
                  <Accordion.Content active={activeIndex === 8}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='exchange_cost'
                          active={this.state.activeItem === '/report/exchange_cost'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exchange_cost'
                        >รายงานค่าเปลี่ยน
                        </Menu.Item>
                        <Menu.Item
                          name='fee_cost'
                          active={this.state.activeItem === '/report/fee_cost'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/fee_cost'
                        >รายงานค่ากำเหน็จ
                        </Menu.Item>
                        <Menu.Item
                          name='weight_diff'
                          active={this.state.activeItem === '/report/weight_diff'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/weight_diff'
                        >รายงานส่วนต่างน้ำหนัก
                        </Menu.Item>
                        <Menu.Item
                          name='buy_gold'
                          active={this.state.activeItem === '/report/buy_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/buy_gold'
                        >รายงานซื้อทอง
                        </Menu.Item>
                        <Menu.Item
                          name='sell_gold'
                          active={this.state.activeItem === '/report/sell_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/sell_gold'
                        >รายงานขายทอง
                        </Menu.Item>
                        <Menu.Item
                          name='exchange_gold'
                          active={this.state.activeItem === '/report/exchange_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exchange_gold'
                        >รายงานเปลี่ยน
                        </Menu.Item>
                        <Menu.Item
                          name='exchange_transaction'
                          active={this.state.activeItem === '/report/exchange_transaction'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/exchange_transaction'
                        >รายงานกำไร(ขาดทุน)จากธุรกรรมเปลี่ยนทอง
                        </Menu.Item>
                        <Menu.Item
                          name='saving_gold'
                          active={this.state.activeItem === '/report/saving_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/saving_gold'
                        >รายงานออมทอง
                        </Menu.Item>
                        <Menu.Item
                          name='lease_interest_out'
                          active={this.state.activeItem === '/report/lease_interest_out'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_interest_out'
                        >รายงานต่อดอก/ไถ่คืน
                        </Menu.Item>
                        <Menu.Item
                          name='lease_cut'
                          active={this.state.activeItem === '/report/lease_cut'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/lease_cut'
                        >รายงานคัดออก
                        </Menu.Item>
                        <Menu.Item
                          name='stock_lease'
                          active={this.state.activeItem === '/report/stock_lease'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_lease'
                        >รายงานสต็อกขายฝาก
                        </Menu.Item>
                        <Menu.Item
                          name='stock_old_gold'
                          active={this.state.activeItem === '/report/stock_old_gold'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_old_gold'
                        >รายงานทองเก่า
                        </Menu.Item>
                        <Menu.Item
                          name='stock_new_gold_weight'
                          active={this.state.activeItem === '/report/stock_new_gold_weight'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_new_gold_weight'
                        >รายงานทองใหม่-ชั่ง
                        </Menu.Item>
                        <Menu.Item
                          name='stock_new_gold_piece'
                          active={this.state.activeItem === '/report/stock_new_gold_piece'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_new_gold_piece'
                        >รายงานทองใหม่-ชิ้น
                        </Menu.Item>
                        <Menu.Item
                          name='stock_gold_99'
                          active={this.state.activeItem === '/report/stock_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_gold_99'
                        >รายงานสต็อกทอง 99.99
                        </Menu.Item>
                        <Menu.Item
                          name='export_gold_99'
                          active={this.state.activeItem === '/report/export_gold_99'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/export_gold_99'
                        >รายงานนำทอง 99.99 ออกจากสต็อก
                        </Menu.Item>
                        <Menu.Item
                          name='overview-sale-change'
                          active={this.state.activeItem === '/report/overview-sale-change'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/overview-sale-change'
                        >รายงานภาพรวมขาย-เปลี่ยน
                        </Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 7}
                    index={7}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='gift' />
                    รายงานของรางวัล
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 7}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='reward_redeem'
                          active={this.state.activeItem === '/report/reward_redeem'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/reward_redeem'
                        >รายงานแลกของรางวัล
                        </Menu.Item>
                        <Menu.Item
                          name='redeem_point'
                          active={this.state.activeItem === '/report/redeem_point'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/redeem_point'
                        >รายงาน Redeem Point
                        </Menu.Item>
                        <Menu.Item
                          name='reward_summary'
                          active={this.state.activeItem === '/report/summary-reward'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/summary-reward'
                        >รายงานสรุปแลกของรางวัล
                        </Menu.Item>
                      </Menu>
                    </p>
                  </Accordion.Content>
                  {['S', 'A', 'M', 'U', 'C'].includes(role) ? <Accordion.Title
                    active={activeIndex === 9}
                    index={9}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='user' />

                    ลูกค้า
                  </Accordion.Title>
                    :
                    ""
                  }
                  <Accordion.Content active={activeIndex === 9}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        {['S', 'A', 'M', 'C'].includes(role) ?
                          <Menu.Item
                            name='reward_redeem'
                            active={this.state.activeItem === '/report/mobile_report'}
                            onClick={this.handleItemClick}
                            as={Link}
                            to='/report/mobile_report'
                          >รายงานจำนวนลูกค้าลงทะเบียนใช้ Mobile Application
                          </Menu.Item> : ''
                        }
                        {['S', 'A', 'M', 'C'].includes(role) ?
                          <Menu.Item
                            name='score_report'
                            active={this.state.activeItem === '/report/score_report'}
                            onClick={this.handleItemClick}
                            as={Link}
                            to='/report/score_report'
                          >รายงานแต้มสะสม
                          </Menu.Item> : ''
                        }
                        {['S', 'A', 'M', 'U', 'C'].includes(role) ?
                          <Menu.Item
                            name='score_report'
                            active={this.state.activeItem === '/report/customer'}
                            onClick={this.handleItemClick}
                            as={Link}
                            to='/report/customer'
                          >ลูกค้าในสาขา
                          </Menu.Item> : ''
                        }
                        {['S', 'A', 'M', 'U', 'C'].includes(role) ?
                          <Menu.Item
                            name='new_customer'
                            active={this.state.activeItem === '/report/new-customer'}
                            onClick={this.handleItemClick}
                            as={Link}
                            to='/report/new-customer'
                          >ลูกค้าใหม่
                          </Menu.Item> : ''
                        }
                      </Menu>
                    </p>
                  </Accordion.Content>
                  { ['S', 'A', 'M', 'C'].includes(role) ? <Accordion.Title
                    active={activeIndex === 10}
                    index={10}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    <Icon name='dolly' />

                    รายงาน AA Gold (รับที่สาขา)
                  </Accordion.Title>
                    :
                    ""
                  }
                  <Accordion.Content active={activeIndex === 10}>
                    <p>
                      <Menu secondary vertical className=' menu-left' size='tiny' style={{ 'margin-left': '10px' }}>
                        <Menu.Item
                          name='stock_ready'
                          active={this.state.activeItem === '/report/stock_ready'}
                          onClick={this.handleItemClick}
                          as={Link}
                          to='/report/stock_ready'
                        >รายงานแลก AA Gold (รับที่สาขา)
                        </Menu.Item>

                      </Menu>
                    </p>
                  </Accordion.Content>
                </Accordion>

              </Segment>
            </Grid.Column>
            <Grid.Column stretched width={13} className="content-body" >
              <div id="content-body">
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/mobile_report" component={() => (<MobileRegisReportPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/score_report" component={() => (<ScoreReportPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/customer" component={() => (<CustomerReportPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/new-customer" component={() => (<NewCustomer items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sell_group" component={() => (<SellGroupPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sell" component={() => (<SellPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sales" component={() => (<SalesPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sell_type" component={() => (<SellTypePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/buy" component={() => (<BuyPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/exc" component={() => (<ExcPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sell_type_amount" component={() => (<SellTypeAmountPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_im" component={() => (<StockInPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_ex" component={() => (<StockEXPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_category_ex" component={() => (<StockCategoryEXPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_sell_buy" component={() => (<StockSellBuyPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_daily2" component={() => (<StokcDaily2PrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_borrow" component={() => (<StockBorrowPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_aa" component={() => (<StockAAPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_borrow_diff" component={() => (<StockBorrowDiffPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_new_gold" component={() => (<StockNewGoldPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_daily" component={() => (<StokcDailyPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_type_amount" component={() => (<StockTypeAmountPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_type_and_code" component={() => (<StockTypeAndCodePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_volume" component={() => (<StockVolumePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_motion" component={() => (<StockMotionPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_not_motion" component={() => (<StockNotMotionPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease" component={() => (<LeasePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_by_bill" component={() => (<LeaseByBillPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_interest" component={() => (<LeaseInterestPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_redeem" component={() => (<LeaseRedeemPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_eject" component={() => (<LeaseEjectPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_due" component={() => (<LeaseDuePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_overdue" component={() => (<LeaseOverduePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_mounth" component={() => (<LeaseMonthPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_group_mounth" component={() => (<LeaseGroupMonthPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_all" component={() => (<LeaseAllPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_stock" component={() => (<LeaseStockPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_leases" component={() => (<StockLeasePrintPreviews items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_lease_all_branch" component={() => (<StockLeaseAllBranchPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_principle" component={() => (<LeasePrinciplePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/lease_history" component={() => (<LeaseInterestHistoryPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/daily" component={() => (<Daily items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_daily" component={() => (<LedgerDaily items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_list" component={() => (<LedgerPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_kind" component={() => (<LedgerKindPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_card" component={() => (<LedgerCardPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_transfer" component={() => (<LedgerTransferPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_check" component={() => (<LedgerCheckPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/ledger_sell" component={() => (<SellPrintPreview2 items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_ready" component={() => (<StockReadyReportPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/stock_consignment" component={() => (<StockConsignmentReport items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/staff_sale" component={() => (<StaffProductStockPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/sell_new_gold" component={() => (<SellNewGoldReport items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/saving" component={() => (<SavingPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/statement" component={() => (<StatementPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/saving_balance_on_date" component={() => (<SavingOnDatePrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/summary-balance" component={() => (<SavingSummaryPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/saving_open" component={() => (<SavingOpeningPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                {/* { role=='A' || role=='M' || role=='S' || role=='U' ?<Route path="/report/saving_close" component={() => (<SavingClosePrintPreview items={[]} clientWidth={clientWidth} />)} />:''} */}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/saving_deposit_withdraw" component={() => (<SavingDepositWithdrawPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/reward_redeem" component={() => (<RedeemPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/redeem_point" component={() => (<RedeemPointReportPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/summary-branch" component={() => (<SummaryBranchReport items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/summary-reward" component={() => (<RewardSummaryPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/safe-summary" component={() => (<SafeSummaryReport items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/safe-statement" component={() => (<SafeStatementReport items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/cash-statement" component={() => (<CashStatementReport items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S', 'C'].includes(role) ? <Route path="/report/buy_average" component={() => (<BuyAverage />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/sell_average" component={() => (<SellAverage />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/motion_stock" component={() => (<MotionStock />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/buy_acc" component={() => (<BuyAccPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/exc_acc" component={() => (<ExcAccPrintPreview />)} /> : ''}
                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/def_acc" component={() => (<DefAccPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/eject_acc" component={() => (<EjectAccPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/stock_old_product_item" component={() => (<StockOldProductItem />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/vat" component={() => (<VatPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/checkvat" component={() => (<CheckVatPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/exchange" component={() => (<ExchangePrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/stock" component={() => (<StockPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/wait_melt" component={() => (<WaitMeltPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/sellaccount" component={() => (<SellAccountPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/commission" component={() => (<CommissionPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/stock_im_account" component={() => (<StockInAccountPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/summary_lease" component={() => (<SummaryLeasePrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/import_gold_99" component={() => (<ImportGold99PrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/difference_gold_melt" component={() => (<DifferenceGoldMeltPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/summary-ledger-transfer" component={() => (<SummaryLedgerTransferPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/lease-income" component={() => (<LeaseIncome />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/lease-summary-income" component={() => (<LeaseSummaryIncome />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/overview" component={() => (<OverviewReport />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/old_gold_melt" component={() => (<OldGoldMeltPrintPreview />)} /> : ''}



                { ['S'].includes(role) ? <Route path="/report/stock_transfer_rejected" component={() => (<StockTransferRejected />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/stock_transfer_cancelled" component={() => (<StockTransferCancelled />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/void_bill" component={() => (<VoidBillPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/void_ledger" component={() => (<LedgerVoidPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/cancel_lease" component={() => (<LeaseCancelPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/cancel_interest" component={() => (<InterestCancelPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/cancel_saving" component={() => (<SavingCancelPrintPreview />)} /> : ''}

                { ['S', 'A', 'M', 'U', 'C'].includes(role) ? <Route path="/report/reward" component={() => (<RewardPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}

                { ['S'].includes(role) ? <Route path="/report/exchange_cost" component={() => (<ExchangeCostPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/buy_gold" component={() => (<BuyGoldPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/fee_cost" component={() => (<FeeCostPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/weight_diff" component={() => (<WeightDiffPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/sell_gold" component={() => (<SellGoldPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/exchange_gold" component={() => (<ExchangeGoldPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/exchange_transaction" component={() => (<ProfitLossFromExchangeGoldPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/saving_gold" component={() => (<SavingGoldPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/lease_interest_out" component={() => (<LeaseInterestOutPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/stock_lease" component={() => (<StockLeasePrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/stock_old_gold" component={() => (<StockOldGoldPrintPreview />)} /> : ''}
                { ['S', 'A', 'C'].includes(role) ? <Route path="/report/stock_old_gold_report" component={() => (<StockOldGoldReportPrintPreview items={[]} clientWidth={clientWidth} />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/lease_cut" component={() => (<LeaseCutPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/stock_new_gold_weight" component={() => (<StockNewGoldWeightPrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/stock_new_gold_piece" component={() => (<StockNewGoldPiecePrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/branch_grading" component={() => (<BranchGradingPrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/export_gold_99" component={() => (<ExportGold99PrintPreview />)} /> : ''}
                { ['S', 'C'].includes(role) ? <Route path="/report/stock_gold_99" component={() => (<StockGold99PrintPreview />)} /> : ''}
                { ['S'].includes(role) ? <Route path="/report/overview-sale-change" component={() => (<OverviewSaleChangePrintPreview />)} /> : ''}
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </Router>
    </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return ({ auth })
}
export default connect(
  mapStateToProps
)(Report)

// export default Report;
